import { useSession } from 'next-auth/react';
import { useContext, useEffect, useState } from 'react';

import classNames from '../../../lib/classnames';
import IsEditionMode from '../../Tiptap/isEditorModeContext';
import type { SubscriberInputPublication } from '../SubscriberFormInput/SubscriberFormInput.interface';
import { checkComponentRender } from './SubscriberForm.service';
import SubscribeToFreeOffer from './SubscribeToFreeOffer';
import SubscribeToPaidOffer from './SubscribeToPaidOffer';

export interface SubscriberFormProps {
    publication: SubscriberInputPublication;
    buttonText?: string;
    buttonClassName?: string;
    className?: string;
    horizontalInput?: boolean;
    goToPaymentFunnel?: boolean;
    alreadySubscribeClassName?: string;
    isSubscribeBanner?: boolean;
}

export type SubscriberFormType = 'none' | 'free' | 'paid';

export default function SubscriberForm({
    publication,
    buttonText,
    buttonClassName,
    className,
    horizontalInput = false,
    goToPaymentFunnel,
    alreadySubscribeClassName,
    isSubscribeBanner = false,
}: SubscriberFormProps): JSX.Element {
    const { data: session } = useSession();
    const [renderedComponent, setRenderedComponent] = useState<SubscriberFormType>('none');
    const isEditionModeValue = useContext(IsEditionMode);

    useEffect(() => {
        if (isEditionModeValue) {
            setRenderedComponent('free');
        } else {
            checkComponentRender(publication, session, setRenderedComponent);
        }
    }, [isEditionModeValue, publication, session]);

    if (renderedComponent === 'free') {
        return (
            <SubscribeToFreeOffer
                publication={publication}
                className={classNames('pb-6', className)}
                buttonText={buttonText}
                buttonClassName={buttonClassName}
                horizontalInput={horizontalInput}
                goToPaymentFunnel={goToPaymentFunnel}
            />
        );
    }
    if (renderedComponent === 'paid') {
        return (
            <SubscribeToPaidOffer
                className={classNames(className)}
                isSubscribeBanner={isSubscribeBanner}
                publication={publication}
            />
        );
    }
    return <p className={classNames(alreadySubscribeClassName)}>Vous êtes abonné</p>;
}
