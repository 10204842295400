import classNames from '../../../../lib/classnames';
import Heading3 from '../../../elements/Heading/Heading3';
import Paragraph from '../../../elements/Paragraph';
import SubscribeToFreeOfferInput from '../../SubscriberFormInput';
import type { SubscriberInputPublication } from '../../SubscriberFormInput/SubscriberFormInput.interface';

export interface SubscribeToFreeOfferProps {
    publication: SubscriberInputPublication;
    buttonText?: string;
    buttonClassName?: string;
    className?: string;
    horizontalInput?: boolean;
    goToPaymentFunnel?: boolean;
}

export default function SubscribeToFreeOffer({
    publication,
    buttonText,
    buttonClassName,
    className,
    horizontalInput = false,
    goToPaymentFunnel,
}: SubscribeToFreeOfferProps): JSX.Element {
    return (
        <div className={classNames('flex flex-col w-full', className)}>
            {publication.title && (
                <Heading3 variant="frontend" className="!mt-0">
                    {publication.title}
                </Heading3>
            )}
            {publication.description && (
                <Paragraph className="mb-[1em] break-words whitespace-normal">
                    {publication.description}
                </Paragraph>
            )}
            <SubscribeToFreeOfferInput
                publication={publication}
                goToPaymentFunnel={goToPaymentFunnel}
                buttonClassName={buttonClassName}
                horizontalInput={horizontalInput}
                buttonText={buttonText}
                className="justify-start"
            />
        </div>
    );
}
