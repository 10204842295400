import type { ResizeSimpleAction } from '@cloudinary/transformation-builder-sdk/actions/resize/ResizeSimpleAction';
import Avatar from 'boring-avatars';

import classNames from '../../../lib/classnames';
import type IProviderMetadata from '../../../shared/interfaces/provider-metadata.interface';
import ImageCloudinary from '../../ImageCloudinary';
import Image from '../Image';

const PALETTE = ['#CAF729', '#79DD7E', '#2ECBAA', '#21B6B6', '#888DDA'];
const WIDTHS = {
    6: '1.5rem',
    8: '2rem',
    10: '2.5rem',
    20: '5rem',
    36: '9rem',
};
const SIZES = {
    6: 'w-6 h-6',
    8: 'w-8 h-8',
    10: 'w-10 h-10',
    20: 'w-20 h-20',
    36: 'w-36 h-36',
};

export interface AvatarPartagerProps {
    variant?: 'marble' | 'beam';
    size?: 6 | 8 | 10 | 20 | 36;
    name?: string;
    square?: boolean;
    src?: string;
    className?: string;
    provider_metadata?: Pick<IProviderMetadata, 'public_id'>;
    resizeParameters?: ResizeSimpleAction;
}

export default function AvatarPartager({
    variant = 'marble',
    size = 10,
    name = 'Partager',
    square = false,
    src,
    className,
    provider_metadata,
    resizeParameters,
}: AvatarPartagerProps): JSX.Element {
    const rounded = square ? 'rounded' : 'rounded-full';

    if (provider_metadata) {
        return (
            <div className={classNames(SIZES[size], rounded, className, 'overflow-hidden')}>
                <ImageCloudinary
                    provider_metadata={provider_metadata}
                    alt={name}
                    resizeParameters={resizeParameters}
                    className="h-full w-full min-h-full object-cover"
                />
            </div>
        );
    }

    if (src) {
        return (
            <div className={classNames(SIZES[size], rounded, className, 'overflow-hidden')}>
                <Image src={src} alt={name} className="h-full w-full min-h-full object-cover" />
            </div>
        );
    }
    return (
        <div className={classNames(SIZES[size], rounded, className, 'overflow-hidden')}>
            <Avatar
                colors={PALETTE}
                variant={variant}
                size={WIDTHS[size]}
                name={name || 'Partager'}
                square={square}
            />
        </div>
    );
}
