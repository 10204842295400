import Head from 'next/head';

interface SeoProps {
    title: string;
    description?: string;
    url?: string;
    image?: string;
    type?: string;
    author?: string;
    createdAt?: string;
    thematic?: string;
    isCanonical?: boolean;
}

export default function Seo({
    title,
    description,
    image,
    type,
    url,
    author,
    createdAt,
    thematic,
    isCanonical = false,
}: SeoProps): JSX.Element {
    const fullUrl = `${process.env.NEXT_PUBLIC_APP_URL}${url}`;
    return (
        <Head>
            <title>{title}</title>
            <meta itemProp="name" content={title} />
            <meta itemProp="description" content={description} />
            <meta itemProp="image" content={image} />

            <meta name="twitter:title" content={title} />
            <meta name="twitter:description" content={description} />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:site" content="@partager.io" />
            <meta name="twitter:creator" content="@partager.io" />
            <meta name="twitter:image:src" content={image} />

            <meta name="author" content={author} />
            <meta name="description" content={description} />

            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />
            <meta property="og:image" content={image} />
            <meta property="og:url" content={fullUrl} />
            <meta property="og:site_name" content="Partager.io" />
            <meta property="og:type" content={type} />
            <meta property="article:published_time" content={createdAt} />
            <meta property="article:author" content={author} />
            <meta property="article:section" content={thematic} />
            {isCanonical && <link rel="canonical" href={fullUrl} />}
        </Head>
    );
}
