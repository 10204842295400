import { AdvancedImage } from '@cloudinary/react';
import type { ResizeSimpleAction } from '@cloudinary/transformation-builder-sdk/actions/resize/ResizeSimpleAction';
import { CloudinaryImage } from '@cloudinary/url-gen';

import classNames from '../../lib/classnames';
import type IProviderMetadata from '../../shared/interfaces/provider-metadata.interface';

interface ImageCloudinaryProps {
    className?: string;
    provider_metadata: Pick<IProviderMetadata, 'public_id'>;
    resizeParameters?: ResizeSimpleAction;
    alt?: string;
}

export default function ImageCloudinary({
    provider_metadata,
    resizeParameters,
    className,
    alt,
}: ImageCloudinaryProps): JSX.Element {
    const image = new CloudinaryImage(provider_metadata.public_id, {
        cloudName: 'partager',
    });
    if (resizeParameters) {
        image.resize(resizeParameters);
    }
    return (
        <AdvancedImage
            cldImg={image}
            alt={alt}
            className={classNames('w-full h-auto', className)}
        />
    );
}
