export interface ISubscriber {
    id: number;
    email: string;
    createdAt: string;
    externalId: string;
}

export enum SubscribeStatus {
    ok = 'En cours',
    pendingUnsubscription = 'En cours de résiliation',
}
