import type { ReactNode } from 'react';

import classNames from '../../../lib/classnames';
import Spinner from '../../basic/Spinner';

export const buttonColor: Record<ButtonColor, string> = {
    primary:
        'text-white bg-gray-800 hover:bg-black focus:ring-gray-300 dark:text-gray-900 dark:bg-gray-50 dark:hover:bg-gray-200 dark:focus:ring-gray-800 dark:border-gray-700 ',
    alternative:
        'text-gray-900 bg-white border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700',
    borderless:
        'text-gray-900 bg-white hover:bg-gray-100 hover:text-blue-700 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700',
    danger: 'text-white bg-red-700 hover:bg-red-800 focus:ring-red-300 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900',
    link: 'underline hover:no-underline active:no-underline focus:no-underline cursor-pointer text-gray-800 underline-offset-2 dark:text-gray-300',
    transparent: 'bg-transparent focus:ring-transparent p-0',
};

export type ButtonSize = 'no-size' | 'extra-small' | 'small' | 'base' | 'large' | 'extra-large';

export const buttonSize: Record<ButtonSize, string> = {
    'no-size': 'p-0 m-0',
    'extra-small': 'py-2 px-3 text-xs ',
    small: 'py-2 px-3 text-sm ',
    base: 'px-2 lg:px-5 py-2 lg:py-2.5 text-sm',
    large: 'py-3 px-5 text-base',
    'extra-large': 'px-6 py-3.5 text-base ',
};

export type ButtonColor =
    | 'primary'
    | 'alternative'
    | 'danger'
    | 'borderless'
    | 'link'
    | 'transparent';

interface ButtonProps {
    color?: ButtonColor;
    size?: ButtonSize;
    type?: 'button' | 'submit' | 'reset';
    loading?: boolean;
    disabled?: boolean;
    loadingMessage?: string;
    children: ReactNode;
    className?: string;
    onClick?: () => void;
}

export default function Button({
    children,
    color = 'primary',
    size = 'base',
    className,
    type = 'button',
    loading = false,
    loadingMessage = '',
    onClick = () => {},
    disabled = loading,
}: ButtonProps): JSX.Element {
    return (
        <button
            className={classNames(
                buttonColor[color],
                buttonSize[size],
                'font-bold rounded-lg focus:z-10 focus:ring-2',
                className,
                { 'flex items-center justify-center gap-3': loading },
            )}
            type={type}
            onClick={onClick}
            disabled={disabled}>
            {loading ? (
                <>
                    <Spinner className="!w-6 !h-6" />
                    {loadingMessage}
                </>
            ) : (
                children
            )}
        </button>
    );
}
