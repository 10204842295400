import type { ReactNode } from 'react';

import classNames from '../../../../lib/classnames';

export interface Heading3Props {
    className?: string;
    level?: number;
    children: ReactNode;
    variant?: string;
    id?: string;
}

export default function Heading3({
    children,
    className,
    level = 3,
    variant,
    id,
}: Heading3Props): JSX.Element {
    let variantClass =
        'font-sans text-sm font-bold text-gray-800 mt-[2em] mb-[1em] dark:text-white';

    if (variant === 'frontend') {
        variantClass =
            'font-sans text-xl lg:text-2xl font-bold text-gray-900 mt-[2em] mb-[1em] dark:text-white';
    }

    return (
        <h3 className={classNames(variantClass, className)} aria-level={level} id={id}>
            {children}
        </h3>
    );
}
