import type { Session } from 'next-auth';
import type { Dispatch, SetStateAction } from 'react';

import type { IPublication } from '../../../features/publication';
import type { PickedSubscription } from '../../../features/subscriber';
import { getUserSubscriptions } from '../../../features/subscriber';
import { isPaidModeAvailable } from '../../../lib/feature-flags';
import type { SubscriberFormType } from './index';

async function getSubscriptionFromPublication(
    publicationId: IPublication['id'],
    userToken: string,
): Promise<PickedSubscription | undefined> {
    const { data: subscriptions } = await getUserSubscriptions(userToken);

    return subscriptions.find((subscription) => subscription.publication.id === publicationId);
}

export function isSubscriptionType(
    subscription: PickedSubscription,
    isOfferPaid: boolean,
): boolean {
    return isOfferPaid
        ? subscription.offerType.name !== 'free'
        : subscription.offerType.name === 'free';
}

export async function getSubscribeFormType(
    publication: Pick<IPublication, 'id' | 'isPaid'>,
    session: Session | null,
): Promise<SubscriberFormType> {
    if (!session) {
        return 'free';
    }

    const subscription = await getSubscriptionFromPublication(publication.id, session.jwt);

    if (!subscription) {
        return 'free';
    }

    if (await isPaidModeAvailable(publication)) {
        if (isSubscriptionType(subscription, true)) {
            return 'none';
        }
        if (isSubscriptionType(subscription, false)) {
            return 'paid';
        }

        return 'free';
    }

    if (isSubscriptionType(subscription, false)) {
        return 'none';
    }

    return 'free';
}

export async function checkComponentRender(
    publication: Pick<IPublication, 'id' | 'isPaid'>,
    session: Session | null,
    setRenderedComponent: Dispatch<SetStateAction<SubscriberFormType>>,
): Promise<void> {
    setRenderedComponent(await getSubscribeFormType(publication, session));
}
