import NextLink from 'next/link';
import type { ReactNode } from 'react';

import classNames from '../../../lib/classnames';

export interface LinkProps {
    href: string;
    className?: string;
    children?: ReactNode;
    type?: 'default' | 'alternative' | 'no-underline';
    target?: string;
    rel?: string;
    onClick?: () => void;
}

export default function Link({
    href,
    className,
    children,
    type = 'default',
    target,
    onClick,
    rel,
}: LinkProps): JSX.Element {
    const STYLES = {
        default: 'underline hover:no-underline active:no-underline focus:no-underline',
        alternative: 'no-underline hover:underline active:underline focus:underline',
        'no-underline': 'no-underline hover:no-underline active:no-underline focus:no-underline',
    };

    return (
        <NextLink href={href}>
            <a
                target={target}
                onClick={onClick}
                href={href}
                rel={rel}
                className={classNames(
                    STYLES[type],
                    'cursor-pointer text-gray-800 underline-offset-2 dark:text-gray-300',
                    className,
                )}>
                {children}
            </a>
        </NextLink>
    );
}
