import { useSession } from 'next-auth/react';
import { useRouter } from 'next/router';
import { useContext, useEffect, useState } from 'react';

import type { IOrderContext } from '../../../../context/OrderContext';
import { OrderContext } from '../../../../context/OrderContext';
import type { IPublication } from '../../../../features/publication';
import classNames from '../../../../lib/classnames';
import Button from '../../../elements/Button';
import Heading3 from '../../../elements/Heading/Heading3';
import styles from '../../../Tiptap/Tiptap.module.css';
import goToPaymentFunnel, { checkPaidRender } from './SubscribeToPaidOffer.service';

interface SubscribeToPaidOfferProps {
    publication: Pick<IPublication, 'id' | 'slug' | 'title'>;
    className?: string;
    isSubscribeBanner?: boolean;
}

export default function SubscribeToPaidOffer({
    publication,
    className,
    isSubscribeBanner,
}: SubscribeToPaidOfferProps): JSX.Element | null {
    const router = useRouter();
    const { updateOrder, removeOrder } = useContext<IOrderContext>(OrderContext);
    const [isSubscribed, setIsSubscribed] = useState<boolean>(false);
    const { data: session } = useSession();

    useEffect(() => {
        if (session) {
            checkPaidRender(publication.id, session.jwt, setIsSubscribed);
        }
    }, [publication.id, session]);

    return (
        <div
            className={classNames('flex flex-col justify-center', {
                'items-center': !isSubscribeBanner,
            })}>
            {isSubscribed ? (
                <p>Vous êtes abonné</p>
            ) : (
                <div className="flex flex-col">
                    {isSubscribeBanner && (
                        <>
                            <Heading3 variant="frontend" className="!mt-0">
                                {publication.title}
                            </Heading3>
                            <div>Vous recevez déjà les articles gratuits de cette publication.</div>
                        </>
                    )}
                    <div
                        className={classNames('flex items-center gap-1', {
                            'flex-col': !isSubscribeBanner,
                        })}>
                        <span>Pour accéder aux articles payants</span>
                        <Button
                            size="no-size"
                            className={classNames(
                                styles.editorButton,
                                'whitespace-nowrap w-min',
                                { '!py-1': isSubscribeBanner },
                                className,
                            )}
                            onClick={() =>
                                goToPaymentFunnel({
                                    router,
                                    publication,
                                    removeOrder,
                                    updateOrder,
                                    session,
                                })
                            }>
                            Abonnez-vous !
                        </Button>
                    </div>
                </div>
            )}
        </div>
    );
}
