import type { ChangeEvent } from 'react';
import type { FieldPath, UseFormRegister } from 'react-hook-form';

import Paragraph from '../../Paragraph';

const states = {
    primary:
        'bg-gray-50 border-gray-300 text-gray-900 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ',
    error: '',
};

const errorClass =
    'bg-red-100 border-red-500 text-red-900 placeholder-red-700 focus:ring-red-500 focus:border-red-500 dark:bg-red-100 dark:border-red-400';

export interface TextInputProps<T> {
    name: FieldPath<T>;
    id?: string;
    state?: 'primary' | 'error';
    errorText?: string;
    disabled?: boolean;
    className?: string;
    register?: UseFormRegister<T>;
    type?: string;
    onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
    placeholder?: string;
    min?: number;
    max?: number;
    value?: number;
}

export default function TextInput<T>({
    id,
    name,
    type = 'text',
    state = 'primary',
    errorText = '',
    disabled = false,
    className,
    register,
    onChange,
    placeholder,
    min,
    max,
    value,
}: TextInputProps<T>): JSX.Element {
    return (
        <>
            <input
                id={id}
                {...(onChange && { onChange })}
                {...(register && register(name))}
                disabled={disabled}
                type={type}
                placeholder={placeholder}
                className={`${states[state]} ${errorText ? errorClass : ''} ${
                    disabled ? 'text-gray-500' : ''
                } shadow-sm border text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:shadow-sm-light ${className}`}
                {...(min && { min })}
                {...(max && { max })}
                {...(value && { value })}
            />
            {errorText ? (
                <Paragraph variant="error" className="mt-2 text-sm text-red-600 dark:text-red-500">
                    {errorText}
                </Paragraph>
            ) : (
                ''
            )}
        </>
    );
}
