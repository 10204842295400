import classNames from '../../../lib/classnames';

interface ImageProps {
    src?: string;
    alt: string;
    className?: string;
}

export default function Image({ src, alt, className }: ImageProps): JSX.Element {
    return (
        <picture>
            <source srcSet={src} />
            <img src={src} alt={alt} className={classNames('w-full h-auto', className)} />
        </picture>
    );
}
