import type { ReactNode } from 'react';

import classNames from '../../../../lib/classnames';

export interface Heading2Props {
    variant?: string;
    level?: number;
    children: ReactNode;
    className?: string;
}

export default function Heading2({
    variant,
    level = 2,
    className,
    children,
}: Heading2Props): JSX.Element {
    let variantClass =
        'font-sans text-lg font-bold text-gray-900 mt-[2em] mb-[1em] dark:text-white';

    if (variant === 'frontend') {
        variantClass =
            'font-sans text-xl lg:text-2xl md:text-3xl font-bold text-gray-900 mt-[2em] mb-[0.5em] md:mb-[1em] dark:text-white';
    }

    return (
        <h2 className={classNames(variantClass, className)} aria-level={level}>
            {children}
        </h2>
    );
}
