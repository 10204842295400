import type { IPublication } from '../features/publication';
import type { IStrapiResponse } from '../shared/interfaces/strapi-response.interface';
import { getAxiosAPI } from './request';

export default async function getFeatureFlagValue(name: string): Promise<boolean> {
    let value = false;
    const { data: featureFlags } = await getAxiosAPI<IStrapiResponse<Array<{ enabled: boolean }>>>(
        `/feature-flags?filters[name][$eq]=${name}`,
    );

    if (featureFlags.data.length) {
        value = featureFlags.data[0]?.enabled;
    }
    return value;
}

export async function getTestBypassFeatureFlagPaidModeByPublicationId(
    id: number,
): Promise<boolean> {
    const response = await getAxiosAPI<IStrapiResponse<{ publicationId: number }>>(
        `/test-bypass-feature-flag-paid-mode`,
    );
    const { publicationId } = response.data.data;
    return publicationId === id;
}

export async function isPaidModeAvailable(
    publication: Pick<IPublication, 'id' | 'isPaid'>,
): Promise<boolean> {
    const isPublicationBypass = await getTestBypassFeatureFlagPaidModeByPublicationId(
        publication.id,
    );

    if (isPublicationBypass) {
        return true;
    }

    return publication.isPaid && (await getFeatureFlagValue('NEXT_PUBLIC_PAID_MODE_ENABLED'));
}
