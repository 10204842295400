import type { ReactNode } from 'react';

import classNames from '../../../lib/classnames';

interface ParagraphProps {
    variant?: 'frontend' | 'error';
    className?: string;
    children: ReactNode;
}

export default function Paragraph({ variant, className, children }: ParagraphProps): JSX.Element {
    let variantClass = 'font-sans text-sm font-normal text-gray-700 dark:text-white';

    if (variant === 'frontend') {
        variantClass = 'font-serif text-lg md:text-xl font-normal text-gray-900 dark:text-white';
    }

    if (variant === 'error') {
        variantClass = 'font-sans text-sm font-normal text-red-600';
    }

    return <p className={classNames(variantClass, className)}>{children}</p>;
}
