import type { Session } from 'next-auth';
import type { NextRouter } from 'next/router';
import type { Dispatch, SetStateAction } from 'react';
import type { ErrorOption, FieldPath } from 'react-hook-form';
import * as yup from 'yup';

import routes from '../../../config/routes';
import type { IOrderContext } from '../../../context/OrderContext';
import type { IPublication } from '../../../features/publication';
import type { PickedSubscription } from '../../../features/subscriber';
import { postSubscribe } from '../../../features/subscriber';
import { isPaidModeAvailable } from '../../../lib/feature-flags';
import type { FormState } from '../../../lib/helper/form-helper';
import { getAxiosAPI } from '../../../lib/request';
import type { IStrapiResponse } from '../../../shared/interfaces/strapi-response';
import type {
    OnSubmitPayload,
    SubscriberInputPublication,
    SubscriberFormFields,
} from './SubscriberFormInput.interface';

export const subscriberFormSchema = yup
    .object({
        email: yup
            .string()
            .email('Cette adresse email ne semble pas valide.')
            .required('Veuillez renseigner votre adresse e-mail'),
    })
    .required();

type SubscribeToPaidOfferArgs = {
    router: NextRouter;
    removeOrder: IOrderContext['removeOrder'];
    updateOrder: IOrderContext['updateOrder'];
    publication: Pick<IPublication, 'id' | 'slug'>;
    email: string;
};

async function subscribeToPaidOffer({
    router,
    removeOrder,
    updateOrder,
    publication,
    email,
}: SubscribeToPaidOfferArgs): Promise<void> {
    removeOrder();
    updateOrder({
        publication,
        price: null,
        email,
        step: 1,
        onlyPaidOffers: false,
    });
    await router.push(routes.chooseAPlan.getUrl());
}

export async function getUserSubscriptionsWithEmail(email: string): Promise<PickedSubscription[]> {
    const encryptedEmail = Buffer.from(email).toString('base64');
    const {
        data: { data: subscriptions },
    } = await getAxiosAPI<IStrapiResponse<PickedSubscription[], { total: number }>>(
        `/subscriptions?email=${encryptedEmail}`,
    );
    return subscriptions;
}

async function getSubscriptionFromPublicationWithEmail(
    publicationId: IPublication['id'],
    email: string,
): Promise<PickedSubscription | undefined> {
    const subscriptions = await getUserSubscriptionsWithEmail(email);
    return subscriptions.find((subscription) => subscription.publication.id === publicationId);
}

async function isPaidSubscribed(
    publicationId: IPublication['id'],
    email: string,
): Promise<boolean> {
    const subscription = await getSubscriptionFromPublicationWithEmail(publicationId, email);
    if (!subscription) {
        return false;
    }

    return subscription.offerType.name !== 'free';
}

async function isFreeSubscribed(
    publicationId: IPublication['id'],
    email: string,
): Promise<boolean> {
    const subscription = await getSubscriptionFromPublicationWithEmail(publicationId, email);
    if (!subscription) {
        return false;
    }

    return subscription.offerType.name === 'free';
}

function showAlreadySubscribed(
    setStatus: Dispatch<SetStateAction<FormState>>,
    setError: (
        name: FieldPath<SubscriberFormFields>,
        error: ErrorOption,
        options?: { shouldFocus: boolean },
    ) => void,
): void {
    setError('email', { type: 'custom', message: 'Vous êtes déjà inscrit.' });
    setStatus('initial');
}

export async function submit({
    data,
    setStatus,
    publication,
    goToPaymentFunnel,
    removeOrder,
    updateOrder,
    router,
    setError,
}: OnSubmitPayload): Promise<void> {
    setStatus('loading');

    const subscriberListFree = publication.subscriberLists.find(
        (subscriberList) => subscriberList.enable && subscriberList.offerType.name === 'free',
    );

    if (!subscriberListFree) {
        return;
    }

    if (goToPaymentFunnel && (await isPaidModeAvailable(publication))) {
        if (await isPaidSubscribed(publication.id, data.email)) {
            showAlreadySubscribed(setStatus, setError);
        } else {
            try {
                await postSubscribe(data.email, publication, subscriberListFree);
            } finally {
                await subscribeToPaidOffer({
                    router,
                    removeOrder,
                    updateOrder,
                    publication,
                    email: data.email,
                });
            }
        }
    } else if (await isFreeSubscribed(publication.id, data.email)) {
        showAlreadySubscribed(setStatus, setError);
    } else {
        await postSubscribe(data.email, publication, subscriberListFree);
        setStatus('success');
    }
}

export function parsePublicationForSubscriberInput(
    publicationString: string,
): SubscriberInputPublication {
    return JSON.parse(publicationString);
}

export function shouldPrefillEmail(
    isEditionModeValue: boolean,
    session: Session | null,
): session is Session {
    return !isEditionModeValue && !!session?.user;
}
