import { getAxiosAPI, putAxiosAPI, postAxiosAPI } from '../../lib/request';
import type IMedia from '../../shared/interfaces/media.interface';
import type { IStrapiResponse } from '../../shared/interfaces/strapi-response';
import type { IOfferType, OfferTypeName } from '../offerType';
import type { IPublication } from '../publication';
import type { ISubscriberList } from '../subscriberList';
import type { ISubscriberSubscriberList } from '../subscriberSubscriberList';
import type { IUser } from '../user';
import type { ISubscriber, SubscribeStatus } from './subscriber.interface';

const urlPlural = '/subscribers';

export async function confirmSubscription(hashedEmail: string, externalId: string): Promise<void> {
    await putAxiosAPI(urlPlural, {
        list: externalId,
        token: hashedEmail,
    });
}

export async function postSubscribe(
    email: string,
    publication: Pick<IPublication, 'id' | 'title' | 'slug'> & {
        logo: Pick<IMedia, 'url'> | null;
    },
    subscriberList: Pick<ISubscriberList, 'id' | 'sourceCode' | 'polarisId' | 'externalId'>,
): Promise<void> {
    await postAxiosAPI(urlPlural, {
        email,
        publication,
        subscriberList,
    });
}

export interface PickedSubscription
    extends Pick<ISubscriberList, 'id' | 'price' | 'externalId' | 'offerPolarisId'> {
    publication: Pick<IPublication, 'id' | 'title' | 'slug' | 'polarisFreeId'> & {
        logo: Pick<IMedia, 'id' | 'url' | 'provider_metadata'> | null;
        user: Pick<IUser, 'id' | 'pseudoName'>;
    };
    subscribers: Array<
        Pick<ISubscriberSubscriberList, 'id'> & {
            subscriber: Pick<ISubscriber, 'id' | 'externalId'>;
            createdAt: string;
        }
    >;
    offerType: Pick<IOfferType, 'id' | 'name' | 'label'>;
    subscribeAt: string;
    status: SubscribeStatus;
}

export async function getUserSubscriptions(
    userToken: string,
    start = 0,
    limit?: number,
): Promise<IStrapiResponse<PickedSubscription[], { total: number }>> {
    const path = '/subscriptions';
    const query = limit ? `?start=${start}&limit=${limit}` : '';
    const { data } = await getAxiosAPI<IStrapiResponse<PickedSubscription[], { total: number }>>(
        `${path}${query}`,
        userToken,
    );

    // TODO add zod to filter these data
    const subscriptions = data.data.filter((subscription) => subscription.publication?.user);

    return {
        meta: data.meta,
        data: subscriptions,
    };
}

export async function unsubscribe(
    email: string,
    subscriberListExternalId: string,
    type: OfferTypeName = 'free',
): Promise<void> {
    await putAxiosAPI('/unsubscribe', {
        email,
        list: subscriberListExternalId,
        type,
    });
}

export async function getPublicationTotalSubscribers(
    publicationId: number,
    userToken: string,
): Promise<{ totalSubscribers: number; totalPaidSubscribers: number }> {
    const response = await getAxiosAPI<{ totalSubscribers: number; totalPaidSubscribers: number }>(
        `/publication/total-subscribers/${publicationId}?type=complex`,
        userToken,
    );
    return response.data;
}

interface PickedSubscriberSubscriberList
    extends Pick<ISubscriberSubscriberList, 'id' | 'updatedAt' | 'belongPartner'> {
    subscriber: Pick<ISubscriber, 'email'>;
    subscriber_list: Pick<ISubscriberList, 'id' | 'price'> & {
        offerType: Pick<IOfferType, 'frequency' | 'name' | 'label'>;
    };
}

// TODO check if it's possible to rework this round only using queryString or at least use a page /pageSize pagination
export async function getPublicationSubscribers(
    publicationId: number,
    start: number,
    limit: number,
    userToken: string,
): Promise<PickedSubscriberSubscriberList[]> {
    const response = await getAxiosAPI<PickedSubscriberSubscriberList[]>(
        `/publication/${publicationId}/subscribers?start=${start}&limit=${limit}`,
        userToken,
    );
    return response.data;
}
