import type { Session } from 'next-auth';
import type { NextRouter } from 'next/router';
import type { Dispatch, SetStateAction } from 'react';

import routes from '../../../../config/routes';
import type { IOrderPayment } from '../../../../features/order';
import { waitingEmail } from '../../../../features/order';
import type { IPublication } from '../../../../features/publication';
import { getUserSubscriptions } from '../../../../features/subscriber';

async function isUserPaidSubscribed(
    publicationId: IPublication['id'],
    userToken: string,
): Promise<boolean> {
    const { data: subscriptions } = await getUserSubscriptions(userToken);

    const foundSubscription = subscriptions.find(
        (subscription) => subscription.publication.id === publicationId,
    );
    if (!foundSubscription) {
        return false;
    }

    return foundSubscription.offerType.name !== 'free';
}

export async function checkPaidRender(
    publicationId: IPublication['id'],
    userToken: string,
    setIsSubscribed: Dispatch<SetStateAction<boolean>>,
): Promise<void> {
    const isSubscribed = await isUserPaidSubscribed(publicationId, userToken);
    setIsSubscribed(isSubscribed);
}

type SubmitArgs = {
    removeOrder: () => void;
    updateOrder: (order: IOrderPayment) => void;
    router: NextRouter;
    publication: Pick<IPublication, 'id' | 'slug'>;
    session: Session | null;
};

export default async function goToPaymentFunnel({
    removeOrder,
    updateOrder,
    router,
    publication,
    session,
}: SubmitArgs): Promise<void> {
    removeOrder();
    updateOrder({
        publication,
        price: null,
        email: session?.user.email || waitingEmail,
        step: 1,
        onlyPaidOffers: true,
    });
    await router.push(routes.chooseAPlan.getUrl());
}
