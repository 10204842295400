import { yupResolver } from '@hookform/resolvers/yup';
import { useSession } from 'next-auth/react';
import { useRouter } from 'next/router';
import type { ReactNode } from 'react';
import { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import type { IOrderContext } from '../../../context/OrderContext';
import { OrderContext } from '../../../context/OrderContext';
import classNames from '../../../lib/classnames';
import type { FormState } from '../../../lib/helper/form-helper';
import Button from '../../elements/Button';
import TextInput from '../../elements/Forms/TextInput';
import Paragraph from '../../elements/Paragraph';
import IsEditionMode from '../../Tiptap/isEditorModeContext';
import type {
    SubscriberFormFields,
    SubscriberInputPublication,
} from './SubscriberFormInput.interface';
import { shouldPrefillEmail, submit, subscriberFormSchema } from './SubscriberFormInput.service';

interface SubscriberFormInputProps {
    publication: SubscriberInputPublication;
    goToPaymentFunnel?: boolean;
    buttonText?: string;
    buttonClassName?: string;
    horizontalInput?: boolean;
    children?: ReactNode;
    className?: string;
}

export default function SubscribeToFreeOfferInput({
    publication,
    goToPaymentFunnel = true,
    buttonText = "S'abonner",
    buttonClassName,
    horizontalInput,
    children,
    className,
}: SubscriberFormInputProps): JSX.Element {
    const [status, setStatus] = useState<FormState>('initial');
    const { updateOrder, removeOrder } = useContext<IOrderContext>(OrderContext);
    const router = useRouter();
    const { data: session } = useSession();
    const isEditionModeValue = useContext(IsEditionMode);

    const {
        register,
        handleSubmit,
        formState: { errors },
        setError,
        setValue,
    } = useForm<SubscriberFormFields>({
        resolver: yupResolver(subscriberFormSchema),
        defaultValues: {
            email: '',
        },
    });

    useEffect(() => {
        if (shouldPrefillEmail(isEditionModeValue, session)) {
            setValue('email', session.user.email);
        }
        return () => setValue('email', '');
    }, [isEditionModeValue, session, setValue]);

    return (
        <>
            {status === 'success' ? (
                <Paragraph className="mt-6">
                    Votre inscription a bien été prise en compte.
                </Paragraph>
            ) : (
                <>
                    <form
                        className={classNames(
                            'w-full flex items-center gap-5',
                            {
                                'flex-wrap': !horizontalInput,
                            },
                            className,
                        )}
                        onSubmit={handleSubmit((data) =>
                            submit({
                                data,
                                setStatus,
                                publication,
                                goToPaymentFunnel,
                                removeOrder,
                                updateOrder,
                                router,
                                setError,
                            }),
                        )}>
                        <TextInput<SubscriberFormFields>
                            name="email"
                            type="email"
                            register={register}
                            disabled={shouldPrefillEmail(isEditionModeValue, session)}
                            placeholder="Votre e-mail"
                            className={classNames('bg-white w-full font-sans')}
                            aria-label="E-mail"
                        />
                        <Button
                            className={classNames(buttonClassName)}
                            type="submit"
                            loading={status === 'loading'}>
                            {buttonText}
                        </Button>
                        {children}
                    </form>
                    {(errors.email?.message || status === 'error') && (
                        <Paragraph variant="error" className="mt-5">
                            {errors.email?.message ??
                                'Une erreur est survenue. Veuillez réessayer plus tard.'}
                        </Paragraph>
                    )}
                </>
            )}
        </>
    );
}
